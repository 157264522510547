<template>
      <ComponenteDatoGeneral titulo=" Incidentes Abiertos vs Cerrados"></ComponenteDatoGeneral>
</template>

<script>
import ComponenteDatoGeneral from '@/components/estadisticas/ComponenteDatoGeneral.vue';
    export default {
        components : {
            ComponenteDatoGeneral
        }
    }
</script>

<style scoped>

</style>